import { useContext, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material';
import { DataTable, Page, Spacer } from '../../components';
import AddAMember from './AddAMember';
import EditAMember from './EditAMember';
import { useAppStorage, useMutation, useObservable } from '@aesop-fables/scrinium';
import { MemberCompartments, membersStorageKey } from '../../data/members';
import { RemoveAMember } from '../../data/members/mutations';
import { MemberData } from '../../api/apis/MembersApi';
import { useNavigate } from 'react-router-dom';
import { sortAdminStatusThenDate } from '../../helpers/sortUtils';
import { capitalize } from 'lodash';
import MembersOffboardingHeader from './MembersOffboardingHeader';
import MembersDashboardHeader from './MembersDashboardHeader';
import RemoveMemberModal from './RemoveMemberModal';
import MemberHeaderRight from './MembersHeaderRight';
import { ReinviteAllMembers, ReinviteMember } from '../../data/members/commands/ReinviteMember';
import { useCommands } from '../../helpers/useCommands';
import { ToastbarContext } from '../../App';
import { Toast } from '../../models/Toast';
import { ExportMembers } from '../../data/members/commands/ExportMembers';
import { OrganizationCompartments, organizationStorageKey } from '../../data/organization';
import { OrganizationData } from '../../models/OrganizationData';
import { SearchMembers } from '../../data/members/commands/SearchMembers';

interface MembersProps {
  variant?: 'offboarding' | 'dashboard';
}

const Members: React.FC<MembersProps> = ({ variant = 'dashboard' }) => {
  const appStorage = useAppStorage();
  const navigate = useNavigate();
  const { setToast } = useContext(ToastbarContext);
  const commands = useCommands();
  const theme = useTheme();
  const [addMemberDrawer, setAddMemberDrawer] = useState(false);
  const [editMemberDrawer, setEditMemberDrawer] = useState(false);
  const [selectedMemberForEdit, setSelectedMemberForEdit] = useState<any>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [activeData, setActiveData] = useState(undefined);

  const memberDataCache = appStorage.retrieve<MemberCompartments>(membersStorageKey);
  const membersData = useObservable(memberDataCache.observe$<MemberData[]>('members'));
  const orgDataCache = appStorage.retrieve<OrganizationCompartments>(organizationStorageKey);
  const orgData = useObservable(orgDataCache.observe$<OrganizationData>('organization'));

  const removeAMember = useMutation(new RemoveAMember());
  const [searchWord, setSearchWord] = useState('');
  const [filteredMembersDataForTable, setFilteredMembersDataForTable] = useState<any[]>([]);

  const onBulkAdd = () => navigate('add');
  const onBulkRemove = () => navigate('remove');

  const offboardingView = variant === 'offboarding';

  const headers = [
    { label: 'firstName', title: 'First Name' },
    {
      label: 'lastName',
      title: 'Last Name',
    },
    {
      label: 'email',
      title: 'Email',
    },
    {
      label: 'status',
      title: 'Status',
    },
  ];

  const transformMembersData = (membersData: MemberData[]) => {
    return membersData.map(member => ({
      firstName: member.firstName,
      lastName: member.lastName,
      email: member.email,
      status:
        capitalize(member.status) +
        ' on ' +
        new Date(member.statusTimestamp).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
      id: member.id,
    }));
  };

  const membersDataForTable = useMemo(() => {
    if (!membersData) return [];
    return transformMembersData(membersData);
  }, [membersData]);

  useEffect(() => {
    const performSearch = async () => {
      if (!searchWord || searchWord.length < 3) {
        setFilteredMembersDataForTable(membersDataForTable);
        return;
      }
      try {
        const searchResults = await commands.execute(SearchMembers, searchWord);
        setFilteredMembersDataForTable(transformMembersData(searchResults.data));
      } catch (err) {
        console.error('Error searching members:', err);
      }
    };
    performSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchWord, membersDataForTable]);

  const handleMemberReinvite = async (row: any) => {
    try {
      await commands.execute(ReinviteMember, row.id);
      setToast(
        new Toast({
          severity: 'success',
          open: true,
          message: 'Member invite sent!',
          autoHideDuration: 3000,
        }),
      );
    } catch (err) {
      console.error('Error reinviting member', err);
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Failed to reinvite member.',
          autoHideDuration: 3000,
        }),
      );
    }
  };

  const handleReinviteAll = async () => {
    try {
      await commands.execute(ReinviteAllMembers, {});
      const invitedCount = membersData?.filter(member => member.status === 'INVITED').length;
      setToast(
        new Toast({
          message: `${invitedCount} member ${invitedCount === 1 ? 'invite' : 'invites'} sent!`,
          severity: 'success',
          open: true,
        }),
      );
    } catch (error) {
      console.error('Error reinviting members', error);
      setToast(
        new Toast({
          message: 'Error reinviting members.',
          severity: 'error',
          open: true,
        }),
      );
    }
  };

  const handleExport = async () => {
    try {
      const response = await commands.execute(ExportMembers, {});
      const blob = new Blob([response.data]);
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = orgData ? `${orgData.name}_members.csv` : 'exported_members.csv';
      link.click();
    } catch (error) {
      console.error('Error downloading the file', error);
      setToast(
        new Toast({
          message: 'Error downloading file.',
          severity: 'error',
          open: true,
        }),
      );
    }
  };

  const handleRemoveMember = async (row: any) => {
    try {
      await removeAMember.action(row.id);
      setToast(
        new Toast({
          severity: 'success',
          open: true,
          message: `${row.firstName} ${row.lastName} has been removed.`,
          autoHideDuration: 3000,
        }),
      );
    } catch (err) {
      console.error(err);
      setToast(
        new Toast({
          severity: 'error',
          open: true,
          message: 'Failed to remove member.',
          autoHideDuration: 3000,
        }),
      );
    }
  };

  const onRemovePress = (row: any) => {
    setModalOpen(true);
    setActiveData(row);
  };

  const onEditPress = (row: any) => {
    setEditMemberDrawer(true);
    setSelectedMemberForEdit(row);
  };

  return (
    <Page
      title={offboardingView ? undefined : 'Members'}
      style={offboardingView ? { margin: 0 } : undefined}>
      <RemoveMemberModal
        onSave={handleRemoveMember}
        activeData={activeData}
        open={modalOpen}
        setOpen={setModalOpen}
      />
      <Spacer height='xxs' />
      {offboardingView ? (
        <MembersOffboardingHeader onBulkAdd={onBulkAdd} setAddMemberDrawer={setAddMemberDrawer} />
      ) : (
        <MembersDashboardHeader
          filteredMembersDataForTable={filteredMembersDataForTable}
          setSearchWord={setSearchWord}
          onBulkAdd={onBulkAdd}
          onBulkRemove={onBulkRemove}
          setAddMemberDrawer={setAddMemberDrawer}
        />
      )}
      <Spacer height='xxs' />
      <DataTable
        headers={headers}
        sortBy='firstName'
        customSort={[
          {
            column: 'status',
            function: sortAdminStatusThenDate,
          },
        ]}
        lastColumnElement='ellipsis'
        ellipsesItems={[
          {
            label: 'Edit',
            onClick: onEditPress,
          },
          {
            label: 'Re-invite',
            onClick: handleMemberReinvite,
            showFn: row => row.status.includes('Invited'),
          },
          {
            label: 'Remove',
            onClick: onRemovePress,
            sx: { color: theme.palette.error.dark },
            showFn: row => !row.status.includes('Removed'),
          },
        ]}
        headerRight={
          <MemberHeaderRight onReinviteAll={handleReinviteAll} onExport={handleExport} />
        }
        data={filteredMembersDataForTable}
        emptyStateText="You haven't added any members yet."
        filtered={searchWord.length > 0}
      />
      <AddAMember drawerOpen={addMemberDrawer} setDrawerOpen={setAddMemberDrawer} />
      <EditAMember
        member={selectedMemberForEdit}
        drawerOpen={editMemberDrawer}
        setDrawerOpen={setEditMemberDrawer}
      />
    </Page>
  );
};

export default Members;
