import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { MembersApi, MemberData } from '../../../api/apis/MembersApi';
import { IDataCommand } from '@aesop-fables/scrinium';
import { AxiosResponse } from 'axios';

export class SearchMembers implements IDataCommand<string, AxiosResponse<MemberData[]>> {
  constructor(@inject(ApiKeys.Members) private readonly api: MembersApi) {}

  async execute(searchText: string): Promise<AxiosResponse<MemberData[]>> {
    return await this.api.searchMembers(searchText);
  }
}
