import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { Disposable } from '../../hooks/useConstant';

import type { DataCompartment, IAppStorage } from '@aesop-fables/scrinium';
import { ScriniumServices } from '@aesop-fables/scrinium';
import { inject, createServiceModule, Scopes } from '@aesop-fables/containr';

import {
  SignUpWizardState,
  InitialSignUpState,
  OnboardingStepsDefault,
  OnboardingStepsAdp,
  StepData,
  OnboardingStepsMultipleProductTypes,
  OrgSignUpStateData,
} from '../../models/SignUpData';
import { SignUpCompartments, signUpStorageKey } from '../../data/signUp';
import { TemplateData } from '../../models/SignUpData';
import { Location } from 'react-router-dom';
import { ApiKeys } from '../../api/apis/ApiKeys';
import { GooglePlacesApi } from '../../api/apis/GooglePlacesApi';
import { PricebookCompartments, pricebookStorageKey } from '../../data/pricebook';
import { PricebookBundle, ProductTypeEnum } from '../../models/PricebookData';
import { OrgSignUpStateApi } from '../../api/apis/OrgSignUpStateApi';
export const signUpWizardKey = 'services/signUpWizard';

export enum OnboardingTemplateIdEnum {
  'Default' = 1,
  'Adp' = 2,
}

const getOnboardingSteps = (
  template: TemplateData,
  multipleProductTypes: boolean = false,
): StepData[] => {
  switch (template?.id) {
    case OnboardingTemplateIdEnum.Adp:
      return OnboardingStepsAdp;
    default:
      if (multipleProductTypes) {
        return OnboardingStepsMultipleProductTypes;
      }
      return OnboardingStepsDefault;
  }
};

class SignUpWizard implements Disposable {
  private readonly state: BehaviorSubject<SignUpWizardState> = new BehaviorSubject(
    InitialSignUpState,
  );

  private readonly signUpComplete: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private templateId = new BehaviorSubject<OnboardingTemplateIdEnum>(
    OnboardingTemplateIdEnum.Default,
  );
  private pricebookOptions = new BehaviorSubject<PricebookBundle[] | undefined>(undefined);
  private multipleProductTypes = new BehaviorSubject<boolean>(false);
  private multiplePricebooks = new BehaviorSubject<boolean>(false);
  private onboardingSteps: StepData[] = OnboardingStepsDefault;
  private googleKey: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    @inject(ScriniumServices.AppStorage)
    private readonly appStorage: IAppStorage,
    @inject(ApiKeys.OrgSignUpState)
    private readonly orgSignUpStateApi: OrgSignUpStateApi,
    @inject(ApiKeys.GooglePlaces)
    private readonly googlePlacesApi: GooglePlacesApi,
  ) {
    const signUpDataCache = this.appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
    const pricebookDataCache = this.appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);

    combineLatest([
      signUpDataCache.observe$<TemplateData>('onboardingTemplate'),
      pricebookDataCache.observe$<PricebookBundle[]>('pricebookOptions'),
    ]).subscribe(() => {
      this.init();
    });
  }

  async init() {
    try {
      const signUpDataCache = this.appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
      const pricebookDataCache =
        this.appStorage.retrieve<PricebookCompartments>(pricebookStorageKey);

      const template = (
        signUpDataCache.findCompartment('onboardingTemplate') as DataCompartment<TemplateData>
      ).getData() as TemplateData;
      const pricebookOptions = (
        pricebookDataCache.findCompartment('pricebookOptions') as DataCompartment<PricebookBundle[]>
      ).getData() as PricebookBundle[];

      const nickelsProducts = pricebookOptions?.some(p =>
        p.pricebook.productType.includes(ProductTypeEnum.Nickels),
      );
      const gcProducts = pricebookOptions?.some(
        p => p.pricebook.productType === ProductTypeEnum.GuidedChoice,
      );
      const multipleProductTypes = nickelsProducts && gcProducts;

      this.templateId.next(template?.id ?? OnboardingTemplateIdEnum.Default);
      this.pricebookOptions.next(pricebookOptions);
      this.multiplePricebooks.next(pricebookOptions?.length > 1);
      this.multipleProductTypes.next(multipleProductTypes);
      this.onboardingSteps = getOnboardingSteps(template, multipleProductTypes);

      const googleKey = await this.googlePlacesApi.get();
      this.googleKey.next(googleKey.data);
    } catch (err) {
      console.error(err);
    }
  }

  get signUpComplete$(): Observable<boolean> {
    return this.signUpComplete;
  }

  get googlePlacesKey$(): Observable<string> {
    return this.googleKey;
  }

  get multipleProductTypes$(): Observable<boolean> {
    return this.multipleProductTypes;
  }

  get multiplePricebooks$(): Observable<boolean> {
    return this.multiplePricebooks;
  }

  getCurrent(location: Location): string {
    return location.pathname.split('onboarding/')[1];
  }

  resumeOnboarding(lastCompletedStep: string): StepData {
    const index = this.onboardingSteps.findIndex(s => s.current === lastCompletedStep);
    const step = this.onboardingSteps.at(index + 1);
    return (
      step ??
      this.onboardingSteps.at(0) ?? {
        current: 'aboutYourOrg',
        route: '/onboarding/aboutYourOrg',
        previousCheckpoint: '',
        totalSteps: 4,
        activeStep: 1,
      }
    );
  }

  nextStep(location: Location): StepData {
    const current = location.pathname.split('onboarding/')[1];
    const index = this.onboardingSteps.findIndex(s => s.current === current);
    const step = this.onboardingSteps.at(index + 1);
    return (
      step ??
      this.onboardingSteps.at(0) ?? {
        current: 'aboutYourOrg',
        route: '/onboarding/aboutYourOrg',
        previousCheckpoint: '',
        totalSteps: 4,
        activeStep: 1,
      }
    );
  }

  currentStep(location: Location): StepData {
    const current = this.getCurrent(location);
    const step = this.onboardingSteps.find(s => s.current === current);
    return (
      step ??
      this.onboardingSteps.at(0) ?? {
        current: 'aboutYourOrg',
        route: '/onboarding/aboutYourOrg',
        previousCheckpoint: '',
        totalSteps: 4,
        activeStep: 1,
      }
    );
  }

  previousStep(location: Location): StepData {
    const current = location.pathname.split('onboarding/')[1];
    const index = this.onboardingSteps.findIndex(s => s.current === current);
    const step = this.onboardingSteps.at(index - 1);
    return (
      step ??
      this.onboardingSteps.at(0) ?? {
        current: 'aboutYourOrg',
        route: '/onboarding/aboutYourOrg',
        previousCheckpoint: '',
        totalSteps: 4,
        activeStep: 1,
      }
    );
  }

  getTotalSteps(): number {
    return this.onboardingSteps.length;
  }

  agreeAndSignUp() {
    this.signUpComplete.next(true);
  }

  reset() {
    this.signUpComplete.next(false);
  }

  dispose(): void {
    console.log('disposing SignUpWizard');
  }

  getCurrentStepIndex(location: Location) {
    const current = location.pathname.split('onboarding/')[1];
    const index = OnboardingStepsDefault.findIndex(s => s.current === current);
    return index + 1;
  }

  async setPricebookCount(selectedProduct: ProductTypeEnum): Promise<void> {
    const pricebookOptions = this.pricebookOptions.getValue();
    if (!pricebookOptions) return;

    const pricebooksCount = pricebookOptions.filter(p =>
      p.pricebook.productType.includes(selectedProduct),
    ).length;

    this.multiplePricebooks.next(pricebooksCount > 1);
  }

  async setProductIfOnlyOneProductType(): Promise<void> {
    const signUpDataCache = this.appStorage.retrieve<SignUpCompartments>(signUpStorageKey);
    const orgSignUpState = (
      signUpDataCache.findCompartment('orgSignUpState') as DataCompartment<OrgSignUpStateData>
    ).getData() as OrgSignUpStateData;

    const templateId = this.templateId.getValue();
    const pricebookOptions = this.pricebookOptions.getValue();

    if (
      templateId !== OnboardingTemplateIdEnum.Adp &&
      !this.onboardingSteps.some(step => step.current === 'chooseProduct')
    ) {
      await this.orgSignUpStateApi.patch({
        state: {
          ...orgSignUpState?.state,
          product:
            pricebookOptions?.[0].pricebook.productType === ProductTypeEnum.GuidedChoice
              ? ProductTypeEnum.GuidedChoice
              : ProductTypeEnum.Nickels,
        },
      });
      await signUpDataCache.reloadAll();
    }
  }
}

export const withSignUpWizard = createServiceModule(signUpWizardKey, services => {
  services.autoResolve<SignUpWizard>(signUpWizardKey, SignUpWizard, Scopes.Singleton);
});

export { SignUpWizard };
