import { Button, Stack } from '@mui/material';
import { Svgs } from '../../assets/svg';
import { Spacer } from '../../components';

interface MembersHeaderRightProps {
  onReinviteAll: () => Promise<void>;
  onExport: () => Promise<void>;
}

const MembersHeaderRight: React.FC<MembersHeaderRightProps> = ({ onReinviteAll, onExport }) => {
  return (
    <Stack
      direction='row'
      spacing={2}
      sx={{
        display: 'flex',
        minWidth: '70%',
        alignItems: 'stretch',
      }}>
      <Button
        variant='outlined'
        color='secondary'
        sx={{
          fontSize: '12px',
          flex: 1,
          whiteSpace: 'nowrap',
        }}
        onClick={onReinviteAll}>
        Reinvite All
      </Button>
      <Button
        color='secondary'
        sx={{
          fontSize: '12px',
          flex: 1,
          whiteSpace: 'nowrap',
        }}
        onClick={onExport}>
        <Svgs.IconExport style={{ flexShrink: 0 }} />
        <Spacer width='xxxs' />
        Export CSV
      </Button>
    </Stack>
  );
};

export default MembersHeaderRight;
