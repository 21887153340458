import React, { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { MoreHoriz as EllipsesIcon } from '@mui/icons-material';

export type EllipsesItem = Omit<MenuItemProps, 'onClick'> & {
  label: React.ReactNode;
  onClick: (row: any) => void;
  showFn?: (row: any) => boolean;
};

interface EllipsesMenuProps extends IconButtonProps {
  items: EllipsesItem[];
  row?: any;
}

const EllipsesMenu: React.FC<EllipsesMenuProps> = ({
  items,
  row,
  color = 'primaryLight',
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const visibleItems = items.filter(item => !item.showFn || item.showFn(row));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (visibleItems.length === 0) return null;

  return (
    <>
      <IconButton onClick={handleClick} size='small' color={color} {...buttonProps}>
        <EllipsesIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {visibleItems.map((item, index) => {
          const { onClick, label, showFn, ...menuProps } = item;
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onClick(row);
                handleClose();
              }}
              {...menuProps}>
              {label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};

export default EllipsesMenu;
