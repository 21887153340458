import { Typography } from '@mui/material';
import { Modal, Spacer } from '../../components';
import { MemberRow } from '../../models/OrganizationData';

interface RemoveMemberModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (row: any) => void;
  activeData: any;
}

const RemoveMemberModal: React.FC<RemoveMemberModalProps> = ({
  open,
  setOpen,
  onSave,
  activeData,
}) => {
  return (
    <Modal
      title='Discontinue member?'
      handleSave={() => onSave(activeData)}
      open={open}
      setOpen={setOpen}
      primaryButtonText='Yes, Remove'
      secondaryButtonText='No, Keep'>
      <Typography variant='p14' color='secondary.main' gutterBottom>
        Are you sure you want to discontinue{' '}
        {(activeData as MemberRow)?.firstName + ' ' + (activeData as MemberRow)?.lastName} as a
        member?
      </Typography>
      <Spacer height='sm' />
      <Typography variant='p14' color='secondary.main' gutterBottom>
        This will remove any association between their account and your organization.
      </Typography>
      <Spacer height='sm' />
    </Modal>
  );
};

export default RemoveMemberModal;
