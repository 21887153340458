import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { MembersApi } from '../../../api/apis/MembersApi';
import { IDataCommand } from '@aesop-fables/scrinium';
import { AxiosResponse } from 'axios';

export class ExportMembers implements IDataCommand<void, AxiosResponse<Blob>> {
  constructor(@inject(ApiKeys.Members) private readonly api: MembersApi) {}

  async execute(): Promise<AxiosResponse<Blob>> {
    return await this.api.exportMembers();
  }
}
