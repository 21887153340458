import { inject } from '@aesop-fables/containr';
import { ApiKeys } from '../../../api/apis/ApiKeys';
import { MembersApi, MemberData } from '../../../api/apis/MembersApi';
import { IDataCommand } from '@aesop-fables/scrinium';
import { AxiosResponse } from 'axios';

export class ReinviteMember implements IDataCommand<number, AxiosResponse<MemberData[], any>> {
  constructor(@inject(ApiKeys.Members) private readonly api: MembersApi) {}

  async execute(memberId: number): Promise<AxiosResponse<MemberData[], any>> {
    return await this.api.reinviteMember(memberId);
  }
}

export class ReinviteAllMembers implements IDataCommand<void, AxiosResponse<MemberData[], any>> {
  constructor(@inject(ApiKeys.Members) private readonly api: MembersApi) {}

  async execute(): Promise<AxiosResponse<MemberData[], any>> {
    return await this.api.reinviteAllMembers();
  }
}
